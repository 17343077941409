@import url("https://fonts.googleapis.com/css?family=Roboto|Staatliches");

.App {
  text-align: center;
  font-family: "Staatliches", serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------- */

.header {
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  background-color: #0a101b;
  color: whitesmoke;
  
  height: 5vh;
  font-size: 2.8vh;
  display: flex;

  align-items: center;

  font-family: "Staatliches", serif;
}

.headerText {
  position: fixed;
  right: 15px;
}

/* ------------- */

.top-toolbar {
  height: 4.5vh;
  width: 70vw;
  padding: 2vh;
}

/* ------------- */

.bottom-toolbar {
  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;

  padding: 2vh;

  width: 70vw;
  height: 4.5vh;
}

/* ------------- */

.string-ui {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70vw;
  padding: 2vh;
}

.completion-indicator {
  animation-duration: 0.75s;
  animation-name: note-indicator-enter;
  animation-fill-mode: backwards;
  animation-delay: 0.2s;
  
  display: flex;

  width: 3.5vh;
  height: 3.5vh;

  background-color: seagreen;
  border-radius: 50%;
  font-size: 3vh;
  color: black;

  align-items: center;
  justify-content: center;

  position: absolute;
  right: 82vw;
}

.string {
  height: 0.6vh;
  width: 60vw;
  margin-right: 5vw;
}

@keyframes note-indicator-enter {
  0% {
    opacity: 0;
    transform: rotate(-180deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}